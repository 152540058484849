export const FirebaseConfig = {
	"projectId": "raftaarcargo-9e1b3",
	"appId": "1:264670268429:web:ca80d79bf4f75179825944",
	"databaseURL": "https://raftaarcargo-9e1b3-default-rtdb.firebaseio.com",
	"storageBucket": "raftaarcargo-9e1b3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDSu7RcPYZaMIGpKcFk10Ms0cSsBr3ke6k",
	"authDomain": "raftaarcargo-9e1b3.firebaseapp.com",
	"messagingSenderId": "264670268429",
	"measurementId": "G-18P3KS5J77"
};